import React from 'react';
import {
  Navbar,
  NavbarBrand,
  NavbarNav,
  NavItem,
  NavLink,
  NavbarToggler,
  Collapse,
  MDBBtn,
  MDBCol,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from 'mdbreact';
import { Form } from '..';
import { connect } from 'react-redux';
import { AccessCheck } from '../../containers';
import logo from '../../assets/images/Logo-01@2x.png';
import { signIn, signOut } from '../../actions/user-actions';
import { MDBRow } from 'mdbreact';

class Header extends React.PureComponent {
  state = {
    isOpen: false,
  };

  toggleCollapse = () => this.setState({ isOpen: !this.state.isOpen });

  render() {
    return (
      <header
        className={`app-header ${this.props.theme ? this.props.theme : ''}`}
        ref={this.headerBlock}
      >
        <div className="header-container">
          <MDBRow>
            <Navbar
              dark
              color="transparent"
              expand="lg"
              className="pl-0 pr-0 z-depth-0 container-fluid"
            >
              <MDBCol className="text-center" xs="12" sm="4" lg="3">
                <NavbarBrand>
                  <NavLink to="/">
                    <img src={logo} className="img-fluid" alt="logo" />
                  </NavLink>
                </NavbarBrand>
              </MDBCol>

              <MDBCol xs="12" sm="8" lg="5">
                <Form.PortalSearch />
              </MDBCol>

              <MDBCol xs="12" sm="12" lg="4">
                <Collapse
                  id="navbarCollapse3"
                  isOpen={this.state.isOpen}
                  navbar
                >
                  <NavbarNav left className="navbar-main text-center">
                    <NavItem>
                      <NavLink to="/about">About</NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink to="/contact">Contact us</NavLink>
                    </NavItem>
                  </NavbarNav>

                  <NavbarNav right className="navbar-auth text-center">
                    <NavItem>
                      <AccessCheck anonimousOnly={true}>
                        <MDBBtn
                          size="sm"
                          color="transparent"
                          className="btn-primary text-uppercase font-weight-bold z-depth-0"
                          onClick={this.props.signIn}
                        >
                          Log In
                        </MDBBtn>
                      </AccessCheck>
                      <AccessCheck userRequired={true}>
                        <MDBDropdown className="user-dropdown">
                          <MDBDropdownToggle
                            nav
                            className="dropdown-toggle"
                            title={`Current email: ${this.props.user.email}`}
                          >
                            <img
                              src={this.props.user.imageUrl}
                              alt="user avatar"
                            />
                          </MDBDropdownToggle>
                          <MDBDropdownMenu basic>
                            <MDBDropdownItem onClick={this.props.signOut}>
                              Log Out
                            </MDBDropdownItem>
                          </MDBDropdownMenu>
                        </MDBDropdown>
                      </AccessCheck>
                    </NavItem>
                  </NavbarNav>
                </Collapse>
              </MDBCol>
            </Navbar>
          </MDBRow>
          <NavbarToggler onClick={this.toggleCollapse} />
        </div>
        {this.props.children}
      </header>
    );
  }
}

export default connect(
  ({ user }) => ({ user }),
  (dispatch) => ({
    signIn: () => dispatch(signIn()),
    signOut: () => dispatch(signOut()),
  })
)(Header);
